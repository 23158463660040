<template>
    <div>
        <select v-if="defecto" :value="selected" @change="$emit('change', $event.target.value)">
            <option :disabled="fraseDisabled" selected :value="fraseValue">{{frase}}</option>
            <optgroup :name="opcion.group ? '' : opcion.name" v-for="(opcion, index) in isGroup(1)" :key="index">
                <option 
                    v-for="(opc, index) in opcion.group"
                    :key="index" 
                    :value="opc.value">
                {{opc.name}}
                </option>
                <option v-if="opcion.value" :value="opcion.value">{{opcion.name}}</option>
            </optgroup>
            <option :value="opcion.value" v-for="(opcion, index) in isGroup(2)" :key="index">
                {{opcion.name}}
            </option>
        </select>
        <div v-else class="select small">
            <div class="select_trigger">
                Ordernar por<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"/></svg>
            </div>
            <div class="select_dropdown"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'VitySelect',
    model:{
        prop: 'selected',
        event: 'change'
    },
    props:{
        defecto: {
            type: Boolean,
            default: false
        },
        frase: {
            type: String,
            default: 'Selecione una opcion'
        },
        fraseValue: {
            type: String,
            default: ''
        },
        fraseDisabled: {
            type: Boolean,
            default: true
        },
        selected:{
            type: String || Number,
            default: ''
        },
        opciones:{
            type: Array,
            default: function(){return []}
            /**
             * :opciones="[
             *      {
             *          value: x,
             *          name: y,
             *      },
             *      {
             *          name: x
             *          group:[
             *              value: x,
             *              name: y,
             *          ]
             *      }
             * ]"
             */
        },
        onChange: {
            type: Function,
            default: () => {}
        }
    },
    methods:{
        isGroup: function(type){
            if(type == 1 && this.opciones[0].group)
                return this.opciones;
            else if(type == 2 && !this.opciones[0].group)
                return this.opciones;
            else
                return [];
        }
    },
}
</script>

<style>
    .select {border: solid 3px #d4d4d4;background-color: #fff;cursor: pointer;font-size: 18px;font-family: 'Quicksand', sans-serif;width: 100%;vertical-align: top;
        border-radius: 10px;
    }
    .select.small {font-size: 14px;line-height: 12px;padding: 6px 15px;}
    .select.small svg {width: 14px;height: 14px;}
    .select svg {fill: #666;width: 18px;height: 18px;margin-left: 10px;vertical-align: top;}
</style>