<template>
    <div class="product_item_wrapper" :class="{full_img: product.img2}">
        <router-link :to="`/productos/${product.url}`" class="product_item_img">
            <figure>
                <div class="primary_img">
                    <img :src="product.img" @error="product.img = require('@/assets/images/img_void.jpg')"/>
                </div>
                <div class="secondary_img">
                    <img :src="product.img2" @error="product.img2 = require('@/assets/images/img_void.jpg')"/>
                </div>
            </figure>
        </router-link>
        <div class="product_info">
            <h2 class="text_s f_500">
                <router-link :to="`/productos/${product.url}`">{{product.name}}</router-link>
            </h2>
            <div class="Product_price">{{new Intl.NumberFormat("es-ES", {style: 'currency', currency: 'EUR'}).format(product.price)}}</div>
            <div class="product_category">
                <span><router-link :to="`/artistas/${product.artist.url}`">{{product.artist.name}}</router-link></span>
                <span v-for="category in product.categories" :key="category.id"><router-link :to="`/productos?category=${category.id}`">{{category.name}}</router-link></span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Producto',
    props:{
        product: Object
    },
    created: function(){
        if(!this.product.img)
            this.product.img = require('@/assets/images/img_void.jpg');
    }
}
</script>