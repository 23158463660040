<template>
    <label class="checkbox" :class="{selected:check }" @click="!input ? (check = !check) : null">
        <input v-if="input" :value="value" type="checkbox" v-model="check"/>
        {{label}}
    </label>
</template>

<script>
export default {
    name: 'VityCheck',
    props:{
        checked: {
            type: Boolean,
            defautl: false
        },
        label: {
            type: String,
            defautl: ''
        },
        value: {
            type: [String, Number, Boolean],
            default: ''
        },
        input: {
            type: Boolean,
            default: true
        }
    },
    watch:{
        checked: function(){
            this.check = this.checked;
        },
        check: function(){
            this.$emit('input', this.check);
        }
    },
    data: function(){
        return{
            check: this.checked
        }
    }
}
</script>

<style scoped>
    label{
        -webkit-user-select: none;  
        -moz-user-select: none;    
        -ms-user-select: none;      
        user-select: none;
    }
</style>